@import '../../../node_modules/materialize-css/sass/materialize.scss';
@import '../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss';

@import 'ubacm/content';
@import 'ubacm/footer';
@import 'ubacm/hero';
@import 'ubacm/navbar';
@import 'ubacm/typography';
@import 'ubacm/variables';

@import 'pages/hack';
@import 'pages/landing';
@import 'pages/schedule';
@import 'pages/space';

*, *::before, *::after {
  box-sizing: border-box;
}

body {
  background-color: $color-90;
  font-family: 'Roboto';
}
