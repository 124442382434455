@import 'ubacm/variables';

.navbar-fixed {
  height: $interact;
}

nav {
  background-color: $color-90;
  height: $interact;
  line-height: $interact;
  transform: translate(0); // Bring it above the hero.

  .brand-logo {
    font-size: 22px;
    font-weight: 700;

    span.branding {
      color: $color-a0;
      margin-left: 75px;
      white-space: nowrap;
    }

    img.logo {
      margin-top: 2px;
      position: absolute;
      height: 50px;
      width: auto;
      z-index: 100;
    }
  }

  ul > li > a {
    color: $color-a20;
    font-size: 18px;
    font-weight: 700;
  }
}

nav,
nav .nav-wrapper i,
nav a.sidenav-trigger,
nav a.sidenav-trigger i {
  color: $color-a20;
  height: $interact;
  line-height: $interact;
}

.sidenav {
  height: 100% !important;

  .user-view {
    align-items: flex-end;
    display: flex;
    height: 180px;

    h4 {
      font-weight: 700;
    }

    .background > img {
      width: 100%;
    }
  }
}

.sidenav li > a > i,
.sidenav li > a > [class^='mdi-'],
.sidenav li > a li > a > [class*='mdi-'],
.sidenav li > a > i.material-icons {
  float: left;
  height: 48px;
  line-height: 48px;
  margin-right: $space;
  width: 24px;
  color: rgba(0, 0, 0, 0.54);
}

.img_icon {
  height: 11px;
  width: auto;
}


@media screen and (min-width: 1000px) {
  .brand-logo {
    margin-left: 50px;
  }
}