$accent-color: #005bbb;
$secondary-color: #00a69c;
$terniary-color: #e56a54;
$white-color: #fff;
$black-color: #000;
$grey-color: #666;
$muted-color: #e4e4e4;
$dark-accent-color: #002f56;

$hack-night-color: #ca2451;

$spacing: 20px;
$interactive-size: 44px;
$rounded-corner: 6px;

$nav-height: 50px;