@import 'variables';

.content {
  background-color: inherit;
  overflow: auto;

  &.more_padding {
    padding-bottom: 60px;
  }

  img {
    width: 100%;
    height: auto;
  }

  // Restore bullet points (thanks materialize)
  ul:not(.browser-default) > li {
    list-style-type: disc;
    margin-left: $space;
  }

.page-description {
  padding-left: 14px;
  padding-bottom: 40px;

}

.flip-card {
  display: inline-block;
  background-color: transparent;
  width: 300px;
  height: 300px;


}
.team {
  padding: 20px;
  margin-left: -4px;

  width: 120%;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-back {
  display: block;
  position: absolute;
  background-color: #002f56 ;
  color: white;
  transform: rotateY(180deg);
  padding : 20px 20px 20px 20px;
}

.flip-card .description {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0); /* Fallback color */
  background: rgba(0, 0, 0, 0.5); /* Black background with 0.5 opacity */
  color: #f1f1f1;
  width: 100%;
  height: 25%;
  padding-top: -10px;
  padding-bottom: 90px;
}


}
