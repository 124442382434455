@import 'ubacm/variables';

.space__hero {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;

  img.logo {
    height: 200px;
    width: auto;
  }

  .subtitle {
    font-size: 20px;
    text-align: center;
    margin-bottom: $space;
  }
}
