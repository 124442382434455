@import 'variables';

.footer {
  background: $color-a10;
  color: $color-80;
  padding: $space 0;

  .logos {
    align-items: center;
    display: flex;
    overflow: hidden;

    a {
      margin-right: $space;

      &:last-of-type {
        margin-right: 0;
      }

      img.logo {
        display: inline-block;
        width: 40px;
        height: auto;
      }
  
      img.logo_wide {
        display: inline-block;
        width: 120px;
        height: auto;
      }
    }
  }
}
