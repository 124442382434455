// Accent colors
$color-a0: #005bbb;
$color-a10: #1e1e32;
$color-a20: #26a69a;
$color-a30: #e56a54;
$color-a40: #ca2451;

// Greyscale colors
$color-0: #000;
$color-10: #666;
$color-80: #b9b9b9;
$color-80: #f1eeee;
$color-90: #ffffff;

$space: 20px;
$interact: 44px;
$round: 6px;

$breakpoint-tablet-portrait: 'min-width: 600px';
$breakpoint-tablet-landscape: 'min-width: 900px';
$breakpoint-desktop: 'min-width: 1200px';
$breakpoint-big-desktop: 'min-width: 1800px';
