@import "../variables";

.googleCalendar {
  text-align: center;
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 50%;
  margin-bottom: 15px;
}

.googleCalendar iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.page__schedule {
  &.past_events {
    .event {
      background: $grey-color;
      color: $muted-color;

      > .copy > .name {
        color: $white-color !important;
      }
    }
  }

  .event {
    align-items: center;
    border: 1px solid $grey-color;
    border-radius: $rounded-corner;
    display: flex;
    padding: $spacing / 2 $spacing;
    position: relative;
    margin-bottom: $spacing;

    > .icon {
      text-align: center;
      display: block;
      width: 60px;

      > img {
        display: inline-block;
        height: auto;
        width: 100%;
      }
    }

    > .copy {
      margin-left: $spacing;

      > .name {
        color: $accent-color;
        font-size: 24px;
        font-weight: 800;

        &.hack_night {
          color: $hack-night-color;
        }
      }

      > .date {
        font-weight: 800;
      }

      > .description {
        min-height: 72px;
      }
    }
  }
}
