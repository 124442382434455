@import 'ubacm/variables';

.hack__hero {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;

  .branding {
    align-items: center;
    display: flex;

    img.logo {
      height: 150px;
      width: auto;
      
      @media screen and ($breakpoint-tablet-landscape) {
        height: 180px;
      }      
    }
  }

  .subtitle {
    font-size: 20px;
    text-align: center;
    margin-bottom: $space;
  }
}


.hack__sponsors {
  text-align: center;
  width: 100%;

  .title_sponsors {
    img {
      height: auto;
      max-width: 300px;
      width: 100%;
    }
  }
  
  .other_sponsors {
    img {
      padding: $space;
      max-width: 220px;
    }
  }
}
 