@import 'ubacm/variables';

.landing__hero {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;

  .branding {
    align-items: center;
    display: flex;

    img.logo {
      height: 90px;
      margin-right: $space;
      width: auto;
    }

    h1 {
      color: $color-a0;
      display: block;
      margin: 0;
      font-weight: 700;
      white-space: nowrap;
    }
  }
  
  .subtitle {
    font-size: 20px;
    text-align: center;
    margin-bottom: $space;
  }
}

.landing__content .block {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  display: block;
  height: 180px;
  padding: $space;
  width: 100%;

  &:hover {
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
    transform: translateY(0); // To make the div stand above for the box-shadow.
    filter: saturate(1.5);
    transition: transform 1s ease, box-shadow 0.3s;
  }

  h4 {
    display: inline-block;
    margin: 0;
    font-weight: 700;

    img {
      display: inline-block;
      height: 100%;
      width: auto;
    }
  }
  
  p {
    display: inline-block;
    margin-bottom: 0;
  }

  .label {
    background-color: rgba(238, 239, 251, 0.95);
    display: inline-flex;
    flex-direction: column;
    padding: $space;
  }

  &[data-image="ubacm"] {
    background-image: url('/assets/stock/people.jpg');
  }
  &[data-image="schedule"] {
    background-image: url('/assets/about/workshop.jpg');
  }
  &[data-image="hack-nights"] {
    background-image: url('/assets/hacknight/hero.jpg');
  }
  &[data-image="hackspace"] {
    background-image: url('/assets/hackspace/coworking.jpg');
  }
}

@media screen and ($breakpoint-tablet-landscape) {
  .landing__content {
    display: flex;

    &:after {
      content: "";
      display: table;
      clear: both;
    }
    
    .block {
      height: 250px;
      float: left;
      width: 50%;
    }
  } 
}